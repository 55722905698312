import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { authData } from '../../components/getAuth';
import axios from 'axios';

export const ComponentToPrintList = React.forwardRef((props, ref) => {
  const auth = authData();
  const [data, setData] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery('page=0&perPage=' + props?.totalCount + '&search=' + props?.filterText + '&start=' + props?.start + '&end=' + props?.end);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [props?.end, props?.filterText, props?.start, props?.status, props?.totalCount]);

  useEffect(() => {
    if (debouncedQuery) {
      axios(process.env.REACT_APP_API_URL + 'v1/assign-bookings?' + debouncedQuery, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        if (response.data.status === 'SUCCESS') {
          setData(response.data.data);
        }
      });
    }
  }, [auth?.api_token, debouncedQuery]);

  return (<div ref={ref} style={{ padding: '20px 20px' }}>

    <table>
      <tr>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Post Code</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Booking Id</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Customer Name</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Pickup Address</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Order Status</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Contact No</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Pickup Date</th>
      </tr>
      {data?.map(row => {
        return <tr>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row?.post_code}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row?.reference_id}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row?.customer_name}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row?.pickup_details?.address}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid' : 'Unpaid')}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row?.pickup_details?.phone}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{moment(row.pickup_date).format('DD/MM/YYYY')}</td>
        </tr>
      })}
    </table>


  </div>);
});