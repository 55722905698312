import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { authData } from '../../components/getAuth';

export const ComponentToPrintList = React.forwardRef((props, ref) => {
  const auth = authData();
  const [data, setData] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery('page=0&perPage=' + props?.totalCount + '&search=' + props?.filterText + '&start=' + props?.start + '&end=' + props?.end + '&status=' + props?.status);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [props?.end, props?.filterText, props?.start, props?.status, props?.totalCount]);

  useEffect(() => {
    if (debouncedQuery) {
      axios(process.env.REACT_APP_API_URL + 'v1/bookings?' + debouncedQuery, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        if (response.data.status === 'SUCCESS') {
          setData(response.data.data);
        }
      });
    }
  }, [auth?.api_token, debouncedQuery]);


  return (<div ref={ref} style={{ padding: '20px 20px' }}>

    <table>
      <tr>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Booking Id</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Customer Name</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Order Date</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Total Value</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Paid Amount</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Order Status</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Pickup Post Code</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Pickup Date</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Created User</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Registered Or Guest User</th>
        <th style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>Complaint Status</th>
      </tr>
      {data?.map(row => {
        return <tr>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row.reference_id}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row.customer_name}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{moment(row.created_at).format('DD/MM/YYYY LT')}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{'£' + parseFloat(row.total).toFixed(2)}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{'£' + parseFloat(row.paid_amount).toFixed(2)}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{(row?.status === -1 || row?.status === '-1') ? 'Cancel' : ((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid' : 'Unpaid')}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row.post_code}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{moment(row.pickup_date).format('DD/MM/YYYY')}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row.created_user}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{row.created_user_type}</td>
          <td style={{ fontSize: '12px', padding: '5px', borderBottom: '#000 1px solid' }}>{'No Complaints'}</td>
        </tr>
      })}
    </table>


  </div>);
});