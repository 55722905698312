import React, { useEffect, useRef, useState } from 'react';
import styles from './list.module.css';
import { TextField, Button, Breadcrumbs, Typography } from '@mui/material';
import DataTable from 'react-data-table-component';
import { X, Plus, UserPlus } from 'react-feather';
import axios from 'axios';
import { TableLoader } from '../../components/LoaderC';
import Loader from '../../components/Loader';
import { authData } from "../../components/getAuth";
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Link } from '@mui/material';
import DriverAddForm from "../driver/addForm";
import AssignPopup from "./AssignPopup";
import withAdminAuth from "../../components/withAdminAuth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ComponentToPrintList } from './ComponentToPrintList';
import { useReactToPrint } from 'react-to-print';
import { DateRangePicker } from 'rsuite';
import CustomTablePagination from '../../components/CustomTablePagination';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = ['post_code', 'booking_id', 'customer_name', 'pickup_address', 'order_status', 'contact_no', 'pickup_date'];

  result = '';
  keys.forEach(key => {
    if (key === 'post_code') {
      result += 'Post Code';
    } else if (key === 'booking_id') {
      result += 'Booking Id';
    } else if (key === 'customer_name') {
      result += 'Customer Name';
    } else if (key === 'pickup_address') {
      result += 'Pickup _Address';
    } else if (key === 'order_status') {
      result += 'Order Status';
    } else if (key === 'contact_no') {
      result += 'Contact No';
    } else if (key === 'pickup_date') {
      result += 'Pickup Date';
    } else {
      result += key;
    }
    result += columnDelimiter;
  });
  result += lineDelimiter;

  array.forEach(item => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="TableAddBU" style={{ width: 'auto' }}>Export To CSV</Button>;

const BookingList = () => {
  const auth = authData();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({ start_date: '', end_date: '' });
  const [addModalShow, setAddModalShow] = useState(false);
  const [assignModalShow, setAssignModalShow] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const componentRef = useRef();

  const columns = [{
    name: 'Post Code',
    selector: row => row?.post_code,
    sortable: true,
  }, {
    name: 'Booking Id',
    selector: row => row?.reference_id,
    sortable: true,
  }, {
    name: 'Customer Name',
    selector: row => row?.customer_name,
    sortable: true,
  }, {
    name: 'Pickup Address',
    selector: row => row?.pickup_details?.address,
    sortable: true,
  }, {
    name: 'Order Status',
    selector: tableProps => (<>
      {((tableProps?.payment_status === 1 || tableProps?.payment_status === '1') ? <Button className={`${styles.StatusBU} ${styles.DisaBU}`}>Paid</Button> : <Button className={`${styles.StatusBU}`}>Unpaid</Button>)}
    </>),
    sortable: true,
  }, {
    name: 'Contact No',
    selector: row => row?.pickup_details?.phone,
    sortable: true,
  }, {
    name: 'Pickup Date',
    selector: row => moment(row.pickup_date).format('DD/MM/YYYY'),
    sortable: true,
  }];

  const customDataTableStyles = {
    head: {
      style: {
        zIndex: 0
      },
    },
    headCells: {
      style: {
        backgroundColor: '#1e1e2d',
        color: '#fff'
      },
    },
  }

  const changeDate = (e) => {
    if (e) {
      setFilterData(prev => {
        return { ...prev, start_date: moment(e[0]).format('YYYY-MM-DD'), end_date: moment(e[1]).format('YYYY-MM-DD') };
      });
    } else {
      setFilterData(prev => {
        return { ...prev, start_date: '', end_date: '' };
      });
    }
  }

  const modalClose = () => {
    setAddModalShow(false);
    setAssignModalShow(false);
  }

  const onSelectedRowsChange = (selRows) => {
    let selectedRowTemp = selRows?.selectedRows.map(item => {
      return item.id;
    });
    setSelectedRows(selectedRowTemp);
  }

  const onSubmitAddForm = () => {
    console.log(111);
  }

  const assignDriver = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });

    setSelectedRows([]);

    axios(process.env.REACT_APP_API_URL + 'v1/assign-bookings?' + debouncedQuery, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        setData(response.data.data);
        setTotalCount(response.data.total);
        setContentLoading(false);
        setLoading(false);
      } else {
        setContentLoading(false);
        setLoading(false);
      }
    }).catch(error => {
      setContentLoading(false);
      setLoading(false);
    });
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery('page=' + page + '&perPage=' + perPage + '&search=' + filterText + '&start=' + filterData?.start_date + '&end=' + filterData?.end_date);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [page, perPage, filterText, filterData]);

  useEffect(() => {
    if (debouncedQuery) {
      setLoading(true);
      axios(process.env.REACT_APP_API_URL + 'v1/assign-bookings?' + debouncedQuery, {
        headers: {
          Authorization: `Bearer ${auth?.api_token}`,
        }
      }).then(response => {
        if (response.data.status === 'SUCCESS') {
          setData(response.data.data);
          setTotalCount(response.data.total);
          setContentLoading(false);
          setLoading(false);
        } else {
          setContentLoading(false);
          setLoading(false);
        }
      }).catch(error => {
        setContentLoading(false);
        setLoading(false);
      });
    }
  }, [debouncedQuery, auth?.api_token]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const downloadCSV = React.useCallback(() => {
    setLoading(true);
    axios(process.env.REACT_APP_API_URL + 'v1/assign-bookings?page=0&perPage=' + totalCount + '&search=' + filterText + '&start=' + filterData?.start_date + '&end=' + filterData?.end_date, {
      headers: {
        Authorization: `Bearer ${auth?.api_token}`,
      }
    }).then(response => {
      if (response.data.status === 'SUCCESS') {
        let allData = response.data.data;
        let newArray = allData.map(row => {
          return { 'post_code': row?.post_code, 'booking_id': row?.reference_id, 'customer_name': row?.customer_name, 'pickup_address': row?.pickup_details?.address.replace(/,/g, ""), 'order_status': ((row?.payment_status === 1 || row?.payment_status === '1') ? 'Paid' : 'Unpaid'), 'contact_no': row?.pickup_details?.phone, 'pickup_date': moment(row.pickup_date).format('DD/MM/YYYY') }
        });

        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(newArray);
        if (csv == null) return;

        const filename = 'driver-list.csv';

        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch(error => {
      setLoading(false);
    });
  }, [auth?.api_token, filterData?.end_date, filterData?.start_date, filterText, totalCount]);

  const actionsMemo = React.useMemo(() => <><Export onExport={() => downloadCSV()} /><Button onClick={handlePrint} className="TableAddBU" style={{ width: 'auto' }}>Print Bookings</Button></>, [downloadCSV, handlePrint]);

  return (<>
    {loading && <Loader />}
    {contentLoading && <div className="LoaderCard"><div className="Loader TableLoader"><TableLoader /></div></div>}
    {!contentLoading && <div className={`${styles.MainDiv}`}>
      <div className={`${styles.PageTitleWrap}`}>
        <p className={`${styles.PageTitle}`}>Assigned To Driver</p>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb'>
          <Typography color="text.primary">Bookings</Typography>
          <Typography color="text.primary">Assign Driver</Typography>
        </Breadcrumbs>
      </div>

      <div className={`${styles.MainCard}`}>
        <div className='TableFilterHead'>
          <div className="DataTableSearch">
            <TextField id="search" type="text" placeholder="Search" aria-label="Search Input" value={filterText} onChange={(e) => setFilterText(e.target.value)} autoComplete='off' />
            {filterText && <Button type="button" className="CloseBU" onClick={(e) => setFilterText('')}><X /></Button>}
          </div>
          <div style={{ width: '320px', marginLeft: '16px' }}>
            <DateRangePicker
              showOneCalendar
              format="dd/MM/yyyy"
              placeholder="Search by date"
              ranges={[]}
              onChange={(e) => changeDate(e)}
            />
          </div>
          <div className='TableFilterBUSec'>
            <Button className="TableAddBU" disabled={selectedRows.length === 0} onClick={(e) => setAssignModalShow(true)}><UserPlus /> Assign</Button>
            <Link className="TableAddBU" onClick={(e) => setAddModalShow(true)}><Plus /> Driver</Link>
          </div>
        </div>

        <div className='TableContent'>
          <div style={{ display: "none" }}><ComponentToPrintList ref={componentRef} totalCount={totalCount} filterText={filterText} start={filterData?.start_date} end={filterData?.end_date} /></div>
          <DataTable
            className='DataTable'
            columns={columns}
            data={data}
            selectableRows
            onSelectedRowsChange={(e) => onSelectedRowsChange(e)}
            customStyles={customDataTableStyles}
            actions={actionsMemo}
            noContextMenu={true}
          />
          <table aria-label="custom pagination table" style={{ position: 'relative', display: 'block', background: '#fff', width: '100 %', height: '40px' }}>
            <tfoot>
              <tr>
                <CustomTablePagination
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                  count={totalCount}
                  rowsPerPage={perPage}
                  page={page}
                  onPageChange={(e, p) => setPage(p)}
                  onRowsPerPageChange={(e) => setPerPage(e.target.value)}
                  slotProps={{
                    select: {
                      'aria-label': 'Rows per page',
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                      slots: {
                        firstPageIcon: FirstPageRoundedIcon,
                        lastPageIcon: LastPageRoundedIcon,
                        nextPageIcon: ChevronRightRoundedIcon,
                        backPageIcon: ChevronLeftRoundedIcon,
                      },
                    },
                  }}
                />
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>}

    <Modal show={addModalShow} onHide={(e) => setAddModalShow(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>Add Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DriverAddForm onSubmit={onSubmitAddForm.bind(this)} modalClose={modalClose.bind(this)} isAddMode={true} id={0} />
      </Modal.Body>
    </Modal>

    <Modal show={assignModalShow} onHide={(e) => setAssignModalShow(false)} centered className='CusModal'>
      <Modal.Header closeButton>
        <Modal.Title>Assign Booking Details To Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AssignPopup onSubmit={assignDriver.bind(this)} modalClose={modalClose.bind(this)} selectedRows={selectedRows} />
      </Modal.Body>
    </Modal>

  </>);

}

export default withAdminAuth(BookingList);