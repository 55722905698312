import { styled } from '@mui/system';
import { TablePagination, tablePaginationClasses as classes } from '@mui/base/TablePagination';

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: #fff;
    position: absolute;
    right: 0;
    padding: 15px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
    font-size: 14px;
    color: #4e4e48;
  }

  & .${classes.select}{
    padding: 2px 0 2px 4px;
    border: 1px solid #DAE2ED;
    border-radius: 6px; 
    background-color: transparent;
    color: #1C2025;
    transition: all 100ms ease;
  }

  & .${classes.displayedRows} {
    margin: 0;
    font-size: 14px;
    color: #4e4e48;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    display: flex;
    gap: 6px;
    border: transparent;
    text-align: center;
  }

  & .${classes.actions} > button {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    color: #1C2025;
    transition: all 100ms ease;

    > svg {
      font-size: 22px;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
  `,
);

export default CustomTablePagination;